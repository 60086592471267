import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert,Button } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/gold3.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
let abu = "#909090"
let cmain = 'black'
let black = 'rgb(38,38,38)'

let id = 'irli-rizki'
let inisial_co = 'Rizki'
let inisial_ce = 'Irli'
let lengkap_co = 'Mohamad Rizki Ramadhan, S.Tr.Im'
let lengkap_ce = 'Irlia Aulia, SE'
let bapak_co = 'Bpk. H. Acep Supriatna, S.E, S.H'
let ibu_co = 'Ibu Hj. Lilis Heni Faridah'
let bapak_ce = "Bpk. H. Moch. Sidik Waskito, B.sc "
let ibu_ce = "Ibu Hj. Lilik Rachmawati"
let ig_co = "rizkiramadhann"
let ig_ce = "i.r.li"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "04/03/2021"

let modal = pw(id, "modal.jpg")
let openlogo = "https://my.possiblewedding.com/wp-content/uploads/logopole.png"

let gmaps = "https://goo.gl/maps/UVNfUx5zNBB8nfhj8"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MzVmNHVxcTdhcXQzMm40anNhaHNkODgzaTQgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com  '
let gmaps1 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.014665818753!2d106.68364011476928!3d-6.261797895467586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb9e836f533b%3A0x5bfb92326eb55f21!2sRumah%20Bapak!5e0!3m2!1sen!2sid!4v1609845976120!5m2!1sen!2sid" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
gmaps1 = gmaps1.split('"')[1]


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        let slide = [
            "1.jpg",
"2.jpg",
"3 (1).jpg",
"4.jpg",
"5.jpg",
"6.jpg",
"7.jpg",
"8.jpg",
"9.jpg",
"IMG_2078.jpg",
        ]
        slide.map(v => {
            slider.push(pw(id, v))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                >
                    <link rel="preconnect" href="https://fonts.gstatic.com"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Yellowtail&display=swap" rel="stylesheet"></link>
<link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap" rel="stylesheet"/> 
<link href="https://fonts.googleapis.com/css2?family=Allura&display=swap" rel="stylesheet"></link>
<link href="https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap" rel="stylesheet"></link>
                </Helm>

                <div id='gold5' style={{
                    backgroundImage: `none`,
                    backgroundColor: '#C5B39B',
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center px-3" style={{ color: cmain }}>
                                        "Dan diantara tanda tanda kekuasaan-Nya diciptakan-Nya untukmu pasangan hidup dari jenismu sendiri supaya kamu mendapat ketenangan hati dan dijadikan-Nya kasih sayang diantara kamu. Sesungguhnya yang demikian menjadi tanda-tanda kekuasaan-Nya bagi orang orang yang berfikir." <br /><br />(Ar-Rum: 21)


                                        </p>
                                    </Item>

                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw(id, "daun.png")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>

                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br />

                                        </p>

                                    </Item>
                                    <Item>
                                        <Col xs={8} md={4}>
                                            <img src={pw(id, "name.png")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <Col xs={8} md={4}>
                                            <img src={pw(id, "name.jfif")} 
                                            style={{borderRadius:'10px'}}
                                            className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>
                                
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', 
                                                    color: cmain,fontFamily:'Dancing Script, cursive' }} >
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                {/* <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item> */}
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{
                                                            fontSize: '24px'
                                                          , color: cmain,
                                                          fontFamily: 'Raleway, sans-serif'
                                                        }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center'
                                                        style={{ fontSize: '18px', color: cmain,
                                                        fontFamily:'Raleway, sans-serif',
                                                        fontStyle:'normal'
                                                        }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw("asset", "logoig-black.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>

                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px',  color: cmain,
                                                    fontFamily: 'Dancing Script, cursive'}} >
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                {/* <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item> */}
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center " style={{
                                                        fontSize: '24px',
                                                        fontFamily:'Raleway, sans-serif',
                                                       color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{
                                                        fontSize: '18px', color: cmain,
                                                        fontFamily:'Raleway, sans-serif',
                                                        fontStyle:'normal'
                                                    }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw("asset", "logoig-black.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>

                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: cmain }} >
                                <Item>
                                        <p style={{fontSize:'16px',fontFamily: 'Raleway, sans-serif'}} >
                                            Yang Insyaa Allah akan dilaksanakan:
                                        </p>
                                    </Item>
                                   
                                        <Item>
                                        <p className="fs20 col-sm-4 px-2" style={{ color: cmain,fontFamily: 'Raleway, sans-serif' }}>
                                            <b>Akad Nikah </b><br />
                                            <span className="cblack fs16">
                                                <b>
                                                    Sabtu, 03 April 2021
                                                </b><br />
                                                14.00 WIB - Selesai<br/>
                                                </span>
                                            </p>
                                        </Item>
       
                                       <Item>
                                       <Container style={{border:'2px solid white'}} className="p-3 m-3">
                                        
                                <p className="fs16 text-center w-100 cblack px-3" 
                                style={{ fontFamily: 'Raleway, sans-serif',color:'black',fontSize:'16px' }}>
                                       <b> Mengingat situasi pandemi covid-19 yang belum mereda, kami mohon 
                                           maaf tidak dapat mengundang Bapak/Ibu/Saudara/i untuk hadir 
                                           pada acara kami secara langsung. Tanpa mengurangi rasa hormat,
                                            Bapak/Ibu/Saudara/i dapat mengikuti acara akad nikah 
                                            kami secara virtual melalui jaringan Youtube berikut ini:
                                       </b></p>
                                <div className="py-2">
                                    <Item>
                                        
                                        <div className="p-1 btn mx-1" onClick={()=>{
                                            window.location.href='https://youtu.be/-y5ycd2dLUw'
                                        }} 
                                        style={{
                                            border:`2px solid white`
                                        }}>
                                            <img src={"https://imagepw.vercel.app/aini-panji/youtube.png"} style={{width:'50px', height:'50px'}}/>
                                        </div>
                                        
                                    </Item>
                                </div>

                                
                                </Container>


                                       </Item>
                                    

                                    {/* <Item>
                                        <p className="fs16 pt-3" style={{ color: cmain }}>
                                        <b>Rumah Bapak</b> <br/>
Jl. Lio Garut No.1, Parigi Baru, Kec. Pd. Aren, Kota Tangerang Selatan, Banten 15228
                                        </p>
                                    </Item>

                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item> */}
                                        
                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="text-center dinny">
                                    <Item>
                                        <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                                            <h1 className="fs30"
                                                style={{

                                                   
                                                    color: cmain
                                                }}>
                                                Protokol Kesehatan
                          </h1>
                                            <Item>
                                                <Col xs={12}>
                                                    <p className="cblack text-center w-100 fs16">
                                                            
                          </p>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Gunakan Masker
                        </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Jaga Jarak
                      </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'salaman.png')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Cukup Bersalaman tanpa Bersentuhan
                      </p>
                                                    </Row>
                                                </Col>
                                            </Item>
                                        </Col>
                                    </Item>

                                    <Item>
                                        <Col>
                                        </Col>

                                    </Item>

                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: 'white' }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14' style={{ fontFamily:'Poppins, sans-serif' ,color:cmain}}>
                                                        50000 years before the sky was introduced to the sea, Allah write down your name next to me. And Finally we find each other.
                             
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw(id, "daun.png")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100 p-2' />
                                        </Col>
                                    </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                                                     </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                       
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Foot ig={pw("asset", "logoig-black.svg")} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

